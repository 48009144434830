import React, { useState, useMemo } from "react";
import { Editor } from "@tinymce/tinymce-react";

import "./_index.scss";
import axios from "axios";
import { store } from "../../App";
import { types } from "../../reducers/types";
import { URL } from "../../api/tklEnvironments";

/*
  Note: We have included the plugin in the same JavaScript file as the TinyMCE
  instance for display purposes only. Tiny recommends not maintaining the plugin
  with the TinyMCE instance and using the `external_plugins` option.  
*/

const ComponentWysiwygEditor = React.memo(function ComponentWysiwygEditor(
  props
) {
  const { type, height, contentHeight } = props;
  const [editorId, setEditorId] = useState("");
  const [upload, setUpload] = useState(true);
  var openDialog = function (editor) {
    return editor.windowManager.open({
      title: "Upload file",
      body: {
        type: "panel",
        items: [
          {
            type: "htmlpanel", // component type
            html: `
          <label for="input-text" class="p2 m-2" style="font-weight:bold">
          Enter File Placeholder
          </label>
          <input style="border: 1px solid #cecece;width:100%;height: calc(1.5em + 0.5rem + 2px);" 
          class="p-2 m-2 form-control form-control-sm rounded-pill"
           type="text" id="input-text"  name="text" value="">
          <label for="fileUpload" class="p2 m-2" style="font-weight:bold">
          File Uploaded
          </label>
          
          <input class="p-2 m-2 rounder-pill" type="file" id="fileUpload"  name="avatar" >
          `,
            //<label for="avatar" class="p2 m-2">
            //Choose a File:</label>
            // <label for="display-name" class="p2 m-2">Display Name:</label><input class="p-2 m-2 rounder-pill"  type="text"  id="displayName"  name="avatar" >
          },
        ],
      },
      buttons: [
        {
          type: "cancel",
          text: "Close",
        },
        {
          type: "submit",
          text: "Save",
          primary: true,
        },
      ],
      onSubmit: async function (api) {
        var data = api.getData();
        const selectedFile = document.getElementById("fileUpload");
        let url = "www.google.com";
        const textPlaceholder = document.getElementById("input-text");
        /* Insert content when the window form is submitted */
        if (selectedFile?.files[0]) {
          var file = selectedFile?.files[0];
          var reader = new FileReader();

          let formData = new FormData();
          formData.append("media[]", file);
          formData.append("type", "FILES");
          store.dispatch({ type: types.SHOW_LOADER });
          await axios
            .post(`${URL}/upload-media`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              if (response.data) {
                store.dispatch({ type: types.HIDE_LOADER });
                let data = response.data?.response?.media[0];
                let newData = {};
                newData["name"] = data.name;
                newData["slug"] = data.slug;
                newData["url"] = data.url;
                let media_URL = `${URL}/stream-media/${data.slug}`;
                url = media_URL;
                //tkl-dev-course.com/api/v1/get-media/react-banner-png-2980600
                // http: reader.readAsDataURL(media_URL);
              }
            })
            .catch(function (error) {
              store.dispatch({ type: types.HIDE_LOADER });
              console.error("..error", error);
            });

          editor.insertContent(
            `<a href="${url}" style="cursor:pointer" target="_blank" download>${
              textPlaceholder?.value || "Default Text"
            }</a>`
          );
          setUpload(false);

          api.close();
        }
        // `<a href=${url} target="_blank">${textPlaceholder?.value}</a>`
      },
    });
  };

  const initMinimal = {
    imagetools_cors_hosts: ["picsum.photos"],
    menubar: "",
    // plugins: "emoticons",
    plugins: "lists link preview",
    toolbar:
      "undo redo bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | blockquote | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link latex anchor codesample | ltr rtl | fontfamily fontsize blocks myCustomToolbarButton",
    toolbar_sticky: false,
    autosave_ask_before_unload: false,
    media_alt_source: false,
    autosave_interval: "30s",
    autosave_prefix: "{path}{query}-{id}-",
    autosave_restore_when_empty: false,
    autosave_retention: "2m",
    image_advtab: true,
    link_list: [
      {
        title: "My page 1",
        value: "http://www.tinymce.com",
      },
      {
        title: "My page 2",
        value: "http://www.moxiecode.com",
      },
    ],
    image_list: [
      {
        title: "My page 1",
        value: "http://www.tinymce.com",
      },
      {
        title: "My page 2",
        value: "http://www.moxiecode.com",
      },
    ],
    image_class_list: [
      {
        title: "None",
        value: "",
      },
      {
        title: "Some class",
        value: "class-name",
      },
    ],
    importcss_append: true,
    height: 250,
    // file_picker_callback: function (callback, value, meta) {
    //   /* Provide file and text for the link dialog */
    //   if (meta.filetype === "file") {
    //     callback("https://www.google.com/logos/google.jpg", {
    //       text: "Sample FIle",
    //     });
    //   }

    //   /* Provide image and alt text for the image dialog */
    //   if (meta.filetype === "image") {
    //     callback("https://www.google.com/logos/google.jpg", {
    //       alt: "Sample Image",
    //     });
    //   }

    //   /* Provide alternative source and posted for the media dialog */
    //   if (meta.filetype === "media") {
    //     callback("movie.mp4", {
    //       source2: "alt.ogg",
    //       poster: "https://www.google.com/logos/google.jpg",
    //     });
    //   }
    // },
    templates: [
      {
        title: "New Table",
        description: "creates a new table",
        content:
          '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
      },
      {
        title: "Starting my story",
        description: "A cure for writers block",
        content: "Once upon a time...",
      },
      {
        title: "New list with dates",
        description: "New List with dates",
        content:
          '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
      },
    ],
    template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
    template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
    image_caption: true,
    // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    quickbars_selection_toolbar: "",
    noneditable_noneditable_class: "mceNonEditable",
    // toolbar_mode: 'sliding',
    toolbar_mode: "wrap",
    contextmenu: "link image imagetools table",
    resize: false,
    // Disable branding message, remove "Powered by TinyMCE"
    branding: false,
  };

  const initFull = {
    // readonly: true,
    selector: "textarea",
    table_style_by_css: true,
    content_css: false,

    setup: (editor) => {
      editor.ui.registry.addButton("myCustomToolbarButton", {
        icon: "upload",
        id: "upload-file",
        onAction: () => {
          openDialog(editor);
        },
      });
      editor.ui.registry.addIcon(
        "myCustomToolbarButton",
        '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white"  viewBox="0 0 576 512"><path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/> <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/> </svg>'
      );

      editor.on("init", function (e) {
        //console.log("editor_id:", e.target);
        setEditorId(e.target);
      });
    },
    imagetools_cors_hosts: ["picsum.photos"],
    menubar: props.hideMenubar
      ? ""
      : "file edit view insert format tools table",
    plugins:
      "paste autoresize importcss searchreplace autolink autosave directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars",
    toolbar: `undo redo | formatting | justifyContent | mediaUpload myCustomToolbarButton link | leftToRight | optionColor | blockquote | outdent indent |  numlist bullist | optionsPrivew | fontfamily fontsize blocks`,
    toolbar_groups: {
      formatting: {
        icon: "format",
        tooltip: "Formatting",
        items: "bold italic underline strikethrough | superscript subscript",
      },
      justifyContent: {
        icon: "align-left",
        tooltip: "align-content",
        items: "alignleft aligncenter alignright alignjustify",
      },
      mediaUpload: {
        icon: "image",
        tooltip: "Upload-Media",
        items: "insertfile image media template anchor codesample",
      },
      leftToRight: {
        icon: "ltr",
        tooltip: "Left-Right",
        items: "ltr rtl",
      },
      optionColor: {
        icon: "text-color",
        tooltip: "color",
        items: "forecolor backcolor removeformat",
      },

      optionsPrivew: {
        icon: "fullscreen",
        tooltip: "Preview-Options",
        items: "preview fullscreen save print",
      },
      // fontSelectItem: {
      //   icon: "format",
      //   tooltip: "Font-Select",
      //   items: "fontselect fontsizeselect formatselect",
      // },
    },
    // toolbar:
    //   "bold italic underline | alignleft aligncenter alignright alignjustify | numlist bullist | save |image link myCustomToolbarButton| fontselect fontsizeselect formatselect | alignment",
    // toolbar_sticky: true,
    // toolbar:
    //   "undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | blockquote | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | fontselect fontsizeselect formatselect | myCustomToolbarButton",

    autosave_ask_before_unload: true,
    media_alt_source: true,
    autosave_interval: "30s",
    autosave_prefix: "{path}{query}-{id}-",
    autosave_restore_when_empty: false,
    autosave_retention: "2m",
    image_advtab: true,
    content_style: `
      img {float: none;margin:.5rem}
      
      table[style*="border-spacing"]:not([style*="border-spacing: 0"]) {
        border-collapse: separate !important;
      }`,

    link_list: [
      {
        title: "My page 1",
        value: "http://www.tinymce.com",
      },
      {
        title: "My page 2",
        value: "http://www.moxiecode.com",
      },
    ],
    image_list: [
      {
        title: "My page 1",
        value: "http://www.tinymce.com",
      },
      {
        title: "My page 2",
        value: "http://www.moxiecode.com",
      },
    ],
    image_class_list: [
      {
        title: "None",
        value: "",
      },
      {
        title: "Some class",
        value: "class-name",
      },
    ],
    importcss_append: true,
    // height: contentHeight || 560,

    height: contentHeight || 400,
    default_link_target: "_blank",
    file_picker_types: "document file image",
    file_picker_callback: (callback, value, meta) => {
      if (meta.filetype === "image") {
        //console.log("callback  :", callback);
        var input = document.getElementById("image-upload-tinymce");
        input.click();

        input.onchange = () => {
          var file = input.files[0];
          var reader = new FileReader();

          let formData = new FormData();
          formData.append("media[]", file);
          formData.append("type", "FILES");
          store.dispatch({ type: types.SHOW_LOADER });
          axios
            .post(`${URL}/upload-media`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              if (response.data) {
                store.dispatch({ type: types.HIDE_LOADER });
                let data = response.data?.response?.media[0];
                let newData = {};
                newData["name"] = data.name;
                newData["slug"] = data.slug;
                newData["url"] = data.url;
                let media_URL = `${URL}/stream-media/${data.slug}`;
                callback(media_URL, {
                  alt: data.name,
                  slug: data.slug,
                });

                //tkl-dev-course.com/api/v1/get-media/react-banner-png-2980600
                http: reader.readAsDataURL(media_URL);
              }
            })
            .catch(function (error) {
              store.dispatch({ type: types.HIDE_LOADER });
              console.error("..error", error);
            });

          // reader.onload = (e) => {
          //   var img = new Image();
          //   img.src = reader.result;

          //   callback(e.target.result, {
          //     alt: file.name,
          //   });

          //   //delay();
          // };
        };
      } else {
        //console.log("value:::value :  ", meta);
      }
    },

    templates: [
      {
        title: "New Table",
        description: "creates a new table",
        content:
          '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
      },
      {
        title: "Starting my story",
        description: "A cure for writers block",
        content: "Once upon a time...",
      },
      {
        title: "New list with dates",
        description: "New List with dates",
        content:
          '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
      },
      {
        title: "Two Coloum Div",
        description: "Two Colom Div",
        content:
          '<div style="box-sizing: border-box;content:"";display: table;clear: both;border:1px solid"> <div style="box-sizing: border-box;float: left;width:50%;padding:10px;"> Sample 1</div> <div style="box-sizing: border-box;float: left;width:50%;padding:10px;"> Sample 2</div> </div>',
      },
      {
        title: "Two Coloum table Borderless",
        description: "Two Colom Div",
        content:
          '<table style="width: 100%;border: none;"> <tr> <td style="width: 50%;border: hidden;">Column 1</td> <td style="width: 50%;border: hidden;">Column 2</td> </tr> </table>',
      },
      {
        title: "Two Coloum With Background",
        description: "Two Colom Div",
        content:
          '<table style="width: 100%;border: none;background-color:lightgray;"> <tr> <td style="width: 50%;border: hidden;background:">Heading 1</td> <td style="width: 50%;border: hidden;">Heading 2</td> </tr> </table>',
      },
    ],
    template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
    template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
    image_caption: true,
    // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    quickbars_selection_toolbar: "",
    noneditable_noneditable_class: "mceNonEditable",
    // toolbar_mode: 'sliding',
    // toolbar_mode: "wrap",
    contextmenu: "link image imagetools table",
    resize: false,
    // Disable branding message, remove "Powered by TinyMCE"
    branding: false,
  };

  const initLimited = {
    //height: "350px",
    readonly: true,
    imagetools_cors_hosts: ["picsum.photos"],
    // plugins: "file  emoticons", //image link media
    toolbar:
      "undo redo bold italic underline strikethrough | blockquote |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen save|  template link codesample | ltr rtl | fontfamily fontsize blocks", //insertfile image media
    toolbar_sticky: true,
    autosave_ask_before_unload: true,
    media_alt_source: false,
    autosave_interval: "30s",
    autosave_prefix: "{path}{query}-{id}-",
    autosave_restore_when_empty: false,
    autosave_retention: "2m",
    image_advtab: true,

    link_list: [],
    image_list: [
      {
        title: "My page 1",
        value: "http://www.tinymce.com",
      },
      {
        title: "My page 2",
        value: "http://www.moxiecode.com",
      },
    ],
    image_class_list: [
      {
        title: "None",
        value: "",
      },
      {
        title: "Some class",
        value: "class-name",
      },
    ],
    importcss_append: true,
    // height: 150,
    // file_picker_callback: function (callback, value, meta) {
    //   /* Provide file and text for the link dialog */
    //   if (meta.filetype === "file") {
    //     callback("https://www.google.com/logos/google.jpg", {
    //       text: "Sample FIle",
    //     });
    //   }

    //   /* Provide image and alt text for the image dialog */
    //   if (meta.filetype === "image") {
    //     callback("https://www.google.com/logos/google.jpg", {
    //       alt: "Sample Image",
    //     });
    //   }

    //   /* Provide alternative source and posted for the media dialog */
    //   if (meta.filetype === "media") {
    //     callback("movie.mp4", {
    //       source2: "alt.ogg",
    //       poster: "https://www.google.com/logos/google.jpg",
    //     });
    //   }
    // },
    // templates: [
    //   {
    //     title: "New Table",
    //     description: "creates a new table",
    //     content:
    //       '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
    //   },
    //   {
    //     title: "Starting my story",
    //     description: "A cure for writers block",
    //     content: "Once upon a time...",
    //   },
    //   {
    //     title: "New list with dates",
    //     description: "New List with dates",
    //     content:
    //       '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
    //   },
    //   {
    //     title: "Two Coloum Div",
    //     description: "Two Colom Div",
    //     content:
    //       '<div style="box-sizing: border-box;content:"";display: table;clear: both;border:1px solid"> <div style="box-sizing: border-box;float: left;width:50%;padding:10px;"> Sample 1</div> <div style="box-sizing: border-box;float: left;width:50%;padding:10px;"> Sample 2</div> </div>',
    //   },
    //   {
    //     title: "Two Coloum table Borderless",
    //     description: "Two Colom Div",
    //     content:
    //       '<table style="width: 100%;border: none;"> <tr> <td style="width: 50%;border: hidden;">Column 1</td> <td style="width: 50%;border: hidden;">Column 2</td> </tr> </table>',
    //   },
    //   {
    //     title: "Two Coloum With Background",
    //     description: "Two Colom Div",
    //     content:
    //       '<table style="width: 100%;border: none;background-color:lightgray;"> <tr> <td style="width: 50%;border: hidden;background:">Heading 1</td> <td style="width: 50%;border: hidden;">Heading 2</td> </tr> </table>',
    //   },
    // ],
    // template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
    // template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
    // image_caption: true,
    // // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    // quickbars_selection_toolbar: "",
    // noneditable_noneditable_class: "mceNonEditable",
    // // toolbar_mode: 'sliding',
    // toolbar_mode: "wrap",
    // contextmenu: "link image imagetools table",
    // resize: false,
    // // Disable branding message, remove "Powered by TinyMCE"
    // branding: false,

    file_picker_callback: (callback, value, meta) => {
      if (meta.filetype === "image") {
        //console.log("callback  :", callback);
        var input = document.getElementById("image-upload-tinymce");
        input.click();

        input.onchange = () => {
          var file = input.files[0];
          var reader = new FileReader();

          let formData = new FormData();
          formData.append("media[]", file);
          formData.append("type", "FILES");

          axios
            .post(`${URL}/upload-media`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              if (response.data) {
                let data = response.data?.response?.media[0];
                let newData = {};
                newData["name"] = data.name;
                newData["slug"] = data.slug;
                newData["url"] = data.url;
                let media_URL = `${URL}/stream-media/${data.slug}`;
                callback(media_URL, {
                  alt: data.name,
                  slug: data.slug,
                });
                reader.readAsDataURL(media_URL);
              }
            })
            .catch(function (error) {
              console.error("..error", error);
            });
        };
      } else {
        //console.log("value:::value :  ", meta);
      }
    },

    templates: [
      {
        title: "New Table",
        description: "creates a new table",
        content:
          '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
      },
      {
        title: "Starting my story",
        description: "A cure for writers block",
        content: "Once upon a time...",
      },
      {
        title: "New list with dates",
        description: "New List with dates",
        content:
          '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
      },
      {
        title: "Two Coloum Div",
        description: "Two Colom Div",
        content:
          '<div style="box-sizing: border-box;content:"";display: table;clear: both;border:1px solid"> <div style="box-sizing: border-box;float: left;width:50%;padding:10px;"> Sample 1</div> <div style="box-sizing: border-box;float: left;width:50%;padding:10px;"> Sample 2</div> </div>',
      },
      {
        title: "Two Coloum table Borderless",
        description: "Two Colom Div",
        content:
          '<table style="width: 100%;border: none;"> <tr> <td style="width: 50%;border: hidden;">Column 1</td> <td style="width: 50%;border: hidden;">Column 2</td> </tr> </table>',
      },
      {
        title: "Two Coloum With Background",
        description: "Two Colom Div",
        content:
          '<table style="width: 100%;border: none;background-color:lightgray;"> <tr> <td style="width: 50%;border: hidden;background:">Heading 1</td> <td style="width: 50%;border: hidden;">Heading 2</td> </tr> </table>',
      },
    ],
    template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
    template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
    image_caption: true,
    // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    quickbars_selection_toolbar: "",
    noneditable_noneditable_class: "mceNonEditable",
    // toolbar_mode: 'sliding',
    toolbar_mode: "wrap",
    contextmenu: "link image imagetools table",
    resize: false,
    // Disable branding message, remove "Powered by TinyMCE"
    branding: false,
  };

  const handleEditorChange = (value) => {
    if (upload) props.courseDescriptionChangeHandler(value, editorId);
    setUpload(true);
  };
  return (
    <>
      <input
        type="file"
        id="image-upload-tinymce"
        name="single-image"
        style={{ display: "none" }}
        accept="application/pdf,application/vnd.ms-excel,image/png, image/gif, image/jpeg, image/jpg, image/svg"
      />
      <Editor
        // initialValue="<img title='TinyMCE Logo' src='//www.tiny.cloud/images/glyph-tinymce@2x.png' alt='TinyMCE Logo' width='110' height='97' /> </p> <h2 style='text-align: center;'>Welcome to the TinyMCE Cloud demo!</h2> <p>Please try out the features provided in this full featured example (excluding <a href='https://www.tiny.cloud/apps/'>Premium Plugins</a> ).</p> <h2>Got questions or need help?</h2> <ul> <li>Our <a class='mceNonEditable' href='//www.tiny.cloud/docs/'>documentation</a> is a great resource for learning how to configure TinyMCE.</li> <li>Have a specific question? Visit the <a class='mceNonEditable' href='https://community.tiny.cloud/forum/'>Community Forum</a>.</li> <li>We also offer enterprise grade support as part of <a href='https://www.tiny.cloud/pricing'>TinyMCE premium plans</a>.</li> </ul> <h2>A simple table to play with</h2> <table style='text-align: center;border-collapse: collapse; width: 100%;'> <thead> <tr> <th>Product</th> <th>Cost</th> <th>Really?</th> </tr> </thead> <tbody> <tr> <td>TinyMCE Cloud</td> <td>Get started for free</td> <td>YES!</td> </tr> <tr> <td>Plupload</td> <td>Free</td> <td>YES!</td> </tr> </tbody> </table> <h2>Found a bug?</h2> <p>If you think you have found a bug please create an issue on the <a href='https://github.com/tinymce/tinymce/issues'>GitHub repo</a> to report it to the developers.</p> <h2>Finally ...</h2> <p>Don't forget to check out our other product <a href='http://www.plupload.com' target='_blank'>Plupload</a>, your ultimate upload solution featuring HTML5 upload support.</p> <p>Thanks for supporting TinyMCE! We hope it helps you and your users create great content.<br>All the best from the TinyMCE team.</p>"
        // apiKey="m6u7gu1fn7rohuu08vqkhbfi9fwaei4jalzehtj9ankjcv6u"
        tinymceScriptSrc='/tinymce/tinymce.min.js'
        licenseKey='T7LK09615AA278F7F7D34F9429B8A2B4D3A77F0FCF04AAB84A64AC5289800A6F'
        className={type === "full" ? "wysiwyg-full" : "wysiwyg-minimal"}
        init={
          type === "full"
            ? initFull
            : type === "initLimited"
            ? initLimited
            : initMinimal
        }
        value={props.editorValue}
        onEditorChange={handleEditorChange}
      />
    </>
  );
});

export default ComponentWysiwygEditor;
